import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import Icon1 from "./GettingStartedStep1.svg";
import Icon2 from "./GettingStartedStep2.svg";
import Icon3 from "./GettingStartedStep3.svg";
import { Button, Card, Container } from "reactstrap";
import { paths } from "../../App";
import { useNavigate } from "react-router-dom";
import { CreateCodeModal } from "./CreateCodeModal";
import { EmailTemplateModal } from "./EmailTemplateModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useEmployerApi } from "../../api/employerApi";
import { Employer } from "../../models/Employer";

export interface LandingPageProps {}

export default function LandingPage(props: LandingPageProps) {
  const { user, isLoading } = useAuth0();
  const [createCodeModalIsOpen, setCreateCodeModalIsOpen] = useState(false);
  const [emailTemplateModalIsOpen, setEmailTemplateModalIsOpen] =
    useState(false);
  const [employer, setEmployer] = useState<Employer | null>(null);
  const { getEmployerInformation } = useEmployerApi();
  const navigate = useNavigate();

  const getEmployerInformationAsync = useCallback(async () => {
    if (isLoading || !user?.sub) return;
    const response = await getEmployerInformation(user.sub);
    if (response === null) {
      setTimeout(() => getEmployerInformationAsync(), 1000);
    }
    setEmployer(response);
  }, [user, isLoading, getEmployerInformation]);

  useEffect(() => {
    getEmployerInformationAsync();
  }, [getEmployerInformationAsync]);
  return (
    <div>
      <AdminLayout
        title={`Welcome, ${employer?.name}`}
        subtitle="Get started with SEPOW in three simple steps."
      >
        <div>
          <Container>
            {!isLoading && (
              <>
                <CreateCodeModal
                  isOpen={createCodeModalIsOpen}
                  setIsOpen={setCreateCodeModalIsOpen}
                />
                <EmailTemplateModal
                  isOpen={emailTemplateModalIsOpen}
                  setIsOpen={setEmailTemplateModalIsOpen}
                />
                <Container className={`card-grid`}>
                  <Card className="step-card">
                    <div>
                      <img src={Icon1} alt="" height="54" className="mb-3" />
                      <h3>Step 1</h3>
                      <h6>Create an Employee Code</h6>
                      <div className="text-black-50">
                        An employee code is used so that your current employees
                        can find your account through the app.
                      </div>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => setCreateCodeModalIsOpen(true)}
                    >
                      Create New Code
                    </Button>
                  </Card>
                  <Card className="step-card">
                    <div>
                      <img src={Icon2} alt="" height="54" className="mb-3" />
                      <h3>Step 2</h3>
                      <h6>Email Your Employees</h6>
                      <div className="text-black-50">
                        Let your employees know that they can rate their
                        experiences working here anonymously. We provide you
                        with a template and additional information to send.
                      </div>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => setEmailTemplateModalIsOpen(true)}
                    >
                      View Email Template
                    </Button>
                  </Card>
                  <Card className="step-card">
                    <div>
                      <img src={Icon3} alt="" height="54" className="mb-3" />
                      <h3>Step 3</h3>
                      <h6>View Your Results</h6>
                      <div className="text-black-50">
                        Once 25% of the employees you invited have filled out
                        the survey, you can view the results on this dashboard.
                      </div>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => navigate(paths.dashboard)}
                    >
                      View Dashboard
                    </Button>
                  </Card>
                </Container>
              </>
            )}
          </Container>
        </div>
      </AdminLayout>
    </div>
  );
}
