import { Dispatch, SetStateAction, useState } from "react";
import { Modal, ModalBody, Button, Row, Col } from "reactstrap";
import { useEmployerApi } from "../../api/employerApi";
import EmailTemplateSample from "./EmailTemplateSample.png";
import { validateEmail } from "../../utilities/validate";
import { Input } from "../../components/Input";

export const EmailTemplateModal = (props: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isOpen, setIsOpen } = props;
  const { sendEmployerCodeEmail } = useEmployerApi();
  const [emailAddresses, setEmailAddresses] = useState([""]);
  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalBody className="p-5 text-center">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mb-4">Email Your Employees</h3>
          <Button close onClick={() => setIsOpen(false)} />
        </div>
        <div className="d-flex flex-row">
          <p className="text-black-50">
            Invite your employees to use SEPOW with you. Employees can access
            our web portal or app through Google Play or the App Store.
            Employees can access the survey using the included employer code.
            SEPOW keeps all employees' personal feelings and identifiable
            information private. Employees should use their personal emails to
            create their account, and understand that their survey submission
            will be anonymous.
          </p>
        </div>
        <div>
          <Row className="mb-4 mt-4">
            <Col xs={3}>
              <Button
                className="me-2"
                color="primary"
                onClick={() => {
                  const addressesCopy = [...emailAddresses];
                  addressesCopy.push("");
                  setEmailAddresses(addressesCopy);
                }}
              >
                Add
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                className="ms-2"
                color="primary"
                onClick={() => {
                  const addressesCopy = [...emailAddresses];
                  addressesCopy.pop();
                  setEmailAddresses(addressesCopy);
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
          {emailAddresses.map((emailAddress, index) => (
            <Input
              className="mb-4"
              key={index}
              value={emailAddress}
              invalid={!validateEmail(emailAddress)}
              onChange={(e) => {
                const addressesCopy = [...emailAddresses];
                addressesCopy[index] = e.target.value;
                setEmailAddresses(addressesCopy);
              }}
            />
          ))}
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            disabled={emailAddresses.some(
              (address) => !validateEmail(address) || !address
            )}
            onClick={() => {
              sendEmployerCodeEmail(emailAddresses);
              setIsOpen(false);
            }}
          >
            Send Email
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
