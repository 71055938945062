import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { useEmployerApi } from "../../api/employerApi";
import { paths } from "../../App";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { validateEmail } from "../../utilities/validate";

export const CreateEmployerAccount = () => {
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const { user } = useAuth0();
  const { createEmployerAccount } = useEmployerApi();
  const navigate = useNavigate();

  const submit = async () => {
    if (name && companyName && validateEmail(email)) {
      await createEmployerAccount({
        id: user?.sub,
        company: companyName,
        name,
        email,
        paid: false,
      });
      navigate(paths.payment);
    } else {
      setNameTouched(true);
      setCompanyNameTouched(true);
      setEmailTouched(true);
    }
  };

  return (
    <AdminLayout>
        <div className="p-4">
          <h2 className="mb-3 text-center">Create Employer Account</h2>
          <Input
            className="mb-3"
            value={companyName}
            onBlur={() => setCompanyNameTouched(true)}
            invalid={companyNameTouched && !companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Company Name"
          />
          <Input
            className="mb-3"
            value={name}
            onBlur={() => setNameTouched(true)}
            invalid={nameTouched && !name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <Input
            className="mb-3"
            value={email}
            onBlur={() => setEmailTouched(true)}
            invalid={emailTouched && !validateEmail(email)}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Button color="primary" onClick={submit}>
            Submit
          </Button>
        </div>
    </AdminLayout>
  );
};
