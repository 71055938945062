import { useCallback } from "react";
import { useApi } from "./api";
import axios from "axios";
import { SuggestEmployer } from "../models/SuggestEmployer";
import { SurveyQuestion } from "../models/Survey";
import {
  EmployeeSavedSurveyResponse,
  EmployeeSurveyResponse,
} from "../models/EmployeeSurveyResponse";

export const useEmployeeApi = () => {
  const { buildUrl, getHeaders } = useApi();

  const saveEmployerCode = useCallback(
    async (code: string) => {
      try {
        await axios.post(
          buildUrl(`employee/code`),
          {
            code,
          },
          {
            headers: await getHeaders(),
          }
        );
        return true;
      } catch (e) {
        return false;
      }
    },
    [buildUrl, getHeaders]
  );

  const findEmployerCode = useCallback(
    async (companyName: string, employeeEmail: string) => {
      await axios.post(
        buildUrl(`employee/lostcode`),
        { companyName, employeeEmail },
        { headers: await getHeaders() }
      );
    },
    [buildUrl, getHeaders]
  );

  const suggestEmployer = useCallback(
    async (employer: SuggestEmployer) => {
      await axios.post(
        buildUrl(`employee/suggestemployer`),
        { ...employer },
        { headers: await getHeaders() }
      );
    },
    [buildUrl, getHeaders]
  );

  const getSurvey = useCallback(async (): Promise<SurveyQuestion[]> => {
    const response = await axios.get(buildUrl(`employee/survey`), {
      headers: await getHeaders(),
    });
    return response.data;
  }, [buildUrl, getHeaders]);

  const saveSurvey = useCallback(
    async (responses: EmployeeSurveyResponse[]) => {
      await axios.post(buildUrl(`employee/survey`), [...responses], {
        headers: await getHeaders(),
      });
    },
    [buildUrl, getHeaders]
  );

  const deleteSurvey = useCallback(async () => {
    await axios.delete(buildUrl(`employee/survey`), {
      headers: await getHeaders(),
    });
  }, [buildUrl, getHeaders]);

  const getEmployeeSavedSurvey = useCallback(async (): Promise<
    EmployeeSavedSurveyResponse[]
  > => {
    const response = await axios.get(buildUrl(`employee/savedsurvey`), {
      headers: await getHeaders(),
    });
    return response.data;
  }, [buildUrl, getHeaders]);

  const getSurveyAverageScore = useCallback(async (): Promise<number> => {
    const response = await axios.get(buildUrl("employee/surveyscore"), {
      headers: await getHeaders(),
    });
    return response.data;
  }, [buildUrl, getHeaders]);

  return {
    saveEmployerCode,
    findEmployerCode,
    suggestEmployer,
    getSurvey,
    saveSurvey,
    deleteSurvey,
    getEmployeeSavedSurvey,
    getSurveyAverageScore,
  };
};
