import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useEmployerApi } from "../../api/employerApi";
import { Input } from "../../components/Input";

export const CreateCodeModal = (props: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isOpen, setIsOpen } = props;
  const [isInputtingCode, setIsInputtingCode] = useState(false);
  const { saveEmployerCode } = useEmployerApi();
  const [code, setCode] = useState("");
  const [employeeTotal, setEmployeeTotal] = useState<number | string>("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setCode("");
      setEmployeeTotal("");
      setIsInputtingCode(false);
      setError(false);
    }
  }, [isOpen]);

  const submit = async () => {
    if (typeof employeeTotal === "string") return;
    const result = await saveEmployerCode({
      code,
      employeeTotal,
    });
    if (result) {
      setIsOpen(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="p-5 text-center w-100">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mb-4">Create Employer Code</h3>
          <Button close onClick={() => setIsOpen(false)} />
        </div>
        {isInputtingCode ? (
          <div>
            <Input
              placeholder="Code"
              value={code}
              invalid={!code || error}
              errorMessage={error ? "Code may already be in use" : ""}
              onChange={(e) => {
                setCode(e.target.value);
                setError(false);
              }}
            />
            <Input
              placeholder="Number of employees"
              value={employeeTotal}
              onChange={(e) => {
                if (e.target.value === "") {
                  setEmployeeTotal("");
                }
                if (Number.isNaN(parseInt(e.target.value))) return;
                setEmployeeTotal(parseInt(e.target.value));
              }}
            />
            <Button
              color="primary"
              disabled={!code || !employeeTotal}
              onClick={submit}
            >
              Save
            </Button>
          </div>
        ) : (
          <>
            <p className="text-black-50">
              {`This code is used to provide survey access to your employees. You can
          reset the code as many times as you would like, but you will have the
          ownership of the code. This means you will be entirely responsible in
          relaying this code to your employees, and they will be required to
          input the code each time it’s been reset. If an employee fails to
          input the correct code, they will be unable to take the survey, submit
          and view their results.`}
            </p>
            <Button onClick={() => setIsInputtingCode(true)} color="primary">
              Create Code
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
