import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { Pie, Bar } from "react-chartjs-2";
import { Row, Spinner } from "reactstrap";
import { useEmployerApi } from "../../api/employerApi";
import { Employer } from "../../models/Employer";
import { SurveyResult } from "../../models/SurveyResult";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
export interface AdminDashboardProps {}

export default function AdminDashboard(props: AdminDashboardProps) {
  const { user, isLoading: auth0Loading } = useAuth0();
  const { getSurveyResults, getSurveyAverageScore, getEmployerInformation } =
    useEmployerApi();
  const [surveyResults, setSurveyResults] = useState<SurveyResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [_, setEmployer] = useState<Employer | null>();
  const [surveyScore, setSurveyScore] = useState(0);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const listOfColors = [
    "#4059AD",
    "#6B9AC4",
    "#D5EBDF",
    "#97D8C4",
    "#1AA053",
    "#FE9436",
  ];

  useEffect(() => {
    (async () => {
      const results = await getSurveyResults();
      setSurveyResults(results);

      const score = await getSurveyAverageScore();
      setSurveyScore(score);
    })();
  }, [getSurveyResults, getSurveyAverageScore]);

  const getEmployerInformationAsync = useCallback(async () => {
    if (auth0Loading || !user?.sub) return;
    setLoading(true);
    const response = await getEmployerInformation(user.sub);
    if (response === null) {
      setTimeout(() => getEmployerInformationAsync(), 1000);
    }
    setEmployer(response);
    setLoading(false);
  }, [user, auth0Loading, getEmployerInformation]);

  useEffect(() => {
    getEmployerInformationAsync();
  }, [getEmployerInformationAsync]);

  const getGraph = (data: SurveyResult) => {
    if (data.graphDisplay === "pie") {
      return (
        <Pie
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              title: {
                display: true,
                text: data.shortQuestion ?? data.questionTitle,
              },
              legend: {
                maxWidth: 150,
                position: "left",
                display: true,
                labels: {
                  boxWidth: 10,
                },
              },
            },
          }}
          data={{
            labels: data.responses.map(
              (r) => r.shortResponse ?? r.responseTitle
            ),
            datasets: [
              {
                clip: 100,
                data: data.responses.map((r) => r.count),
                backgroundColor: listOfColors,
              },
            ],
          }}
        />
      );
    } else if (data.graphDisplay === "column") {
      return (
        <Bar
          options={{
            indexAxis: "x",
            scales: {
              y: { ticks: { precision: 0 } },
            },
            plugins: {
              title: {
                display: true,
                text: data.shortQuestion ?? data.questionTitle,
              },
              legend: { display: false },
            },
            responsive: true,
          }}
          data={{
            labels: data.responses.map(
              (r) => r.shortResponse ?? r.responseTitle
            ),
            datasets: [
              {
                barThickness: 50,
                data: data.responses.map((r) => r.count),
                backgroundColor: "#4059AD",
              },
            ],
          }}
        />
      );
    } else return <div />;
  };

  return (
    <div>
      <AdminLayout>
        <div className="mx-auto">
          {loading ? (
            <div className="vh-100 d-flex justify-content-center align-items-center">
              <Spinner size="lg" />
            </div>
          ) : surveyResults.length === 0 ? (
            <div className="container">
              No results yet, check back after some of your employees have
              completed the survey.
            </div>
          ) : (
            <div
              className="container"
              style={{ maxWidth: "1200px", padding: "50px " }}
            >
              <h3 className="text-center">{`Inclusive Score: ${surveyScore.toPrecision(
                2
              )}`}</h3>
              {surveyResults.map((result, index) => {
                return (
                  <Row key={index} className="pb-5">
                    {getGraph(result)}
                  </Row>
                );
              })}
            </div>
          )}
        </div>
      </AdminLayout>
    </div>
  );
}
