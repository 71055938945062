import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const buildUrl = useMemo(
    () => (path: string) => {
      if (process.env.REACT_APP_API_URL === undefined) {
        throw new Error("No API url defined");
      }
      return new URL(path, process.env.REACT_APP_API_URL).toString();
    },
    []
  );

  const getHeaders = useMemo(
    () => async () => {
      return {
        Authorization: `Bearer ${await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_URL,
        })}`,
      };
    },
    [getAccessTokenSilently]
  );

  const helloWorld = async () => {
    getHeaders();
    const response = await axios.get(buildUrl("WeatherForecast"), {
      headers: await getHeaders(),
    });
    return response.data;
  };

  return { buildUrl, getHeaders, helloWorld };
};

export { useApi };
