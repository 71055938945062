import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEmployerApi } from "../../api/employerApi";
import { paths } from "../../App";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

export const PaymentConfirmation = () => {
  const { confirmEmployerIsPaid } = useEmployerApi();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(paths.landingPage, { replace: true });
      toast("You can now access all SEPOW features.", { type: "success" });
      // Have to give it a few seconds, otherwise it will redirect back to the payment page
    }, 2000);
  }, [navigate]);

  useEffect(() => {
    const locationUrl = new URL(window.location.href);
    const paymentIntentId = locationUrl.searchParams.get("payment_intent");
    if (!paymentIntentId) return;
    confirmEmployerIsPaid(paymentIntentId);
  }, [confirmEmployerIsPaid]);

  return (
    <div className="d-flex vw-100 vh-100 justify-content-center align-items-center">
      <Spinner size="lg" />
    </div>
  );
};
