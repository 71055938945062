import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { SurveyQuestion } from "../../models/Survey";
import Star from "./star-selected.svg";
import StarUnselected from "./star-unselected.svg";

export const StarsQuestion = (props: {
  question: SurveyQuestion;
  responsesSelected: string | null;
  handleClick: (value: string) => void;
}) => {
  const { question, responsesSelected, handleClick } = props;
  const [starSize, setStarSize] = useState(0);

  useEffect(() => {
    const setSize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 1000) {
        setStarSize(100);
      } else if (windowWidth > 500) {
        setStarSize(60);
      } else {
        setStarSize(45);
      }
    };
    window.addEventListener("resize", (e) => {
      setSize();
    });

    setSize();
  }, []);

  return (
    <>
      <h2 className="mb-5">{question.question}</h2>
      <div className="d-flex justify-content-center align-items-center">
        <Row className="d-flex flex-row mb-4">
          <Col>
            <img
              src={
                responsesSelected !== null &&
                (responsesSelected === question.surveyResponseOptions[0].id ||
                  responsesSelected === question.surveyResponseOptions[1].id ||
                  responsesSelected === question.surveyResponseOptions[2].id ||
                  responsesSelected === question.surveyResponseOptions[3].id ||
                  responsesSelected === question.surveyResponseOptions[4].id)
                  ? Star
                  : StarUnselected
              }
              onClick={() => handleClick(question.surveyResponseOptions[0].id)}
              alt="Star"
              role="button"
              height={starSize}
              width={starSize}
              className="ms-1 me-1"
            />
            <img
              src={
                responsesSelected !== null &&
                (responsesSelected === question.surveyResponseOptions[1].id ||
                  responsesSelected === question.surveyResponseOptions[2].id ||
                  responsesSelected === question.surveyResponseOptions[3].id ||
                  responsesSelected === question.surveyResponseOptions[4].id)
                  ? Star
                  : StarUnselected
              }
              onClick={() => handleClick(question.surveyResponseOptions[1].id)}
              alt="Star"
              role="button"
              height={starSize}
              width={starSize}
              className="ms-1 me-1"
            />
            <img
              src={
                responsesSelected !== null &&
                (responsesSelected === question.surveyResponseOptions[2].id ||
                  responsesSelected === question.surveyResponseOptions[3].id ||
                  responsesSelected === question.surveyResponseOptions[4].id)
                  ? Star
                  : StarUnselected
              }
              onClick={() => handleClick(question.surveyResponseOptions[2].id)}
              alt="Star"
              role="button"
              height={starSize}
              width={starSize}
              className="ms-1 me-1"
            />
            <img
              src={
                responsesSelected !== null &&
                (responsesSelected === question.surveyResponseOptions[3].id ||
                  responsesSelected === question.surveyResponseOptions[4].id)
                  ? Star
                  : StarUnselected
              }
              onClick={() => handleClick(question.surveyResponseOptions[3].id)}
              alt="Star"
              role="button"
              height={starSize}
              width={starSize}
              className="ms-1 me-1"
            />
            <img
              src={
                responsesSelected !== null &&
                responsesSelected === question.surveyResponseOptions[4].id
                  ? Star
                  : StarUnselected
              }
              onClick={() => handleClick(question.surveyResponseOptions[4].id)}
              alt="Star"
              role="button"
              height={starSize}
              width={starSize}
              className="ms-1 me-1"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
