import { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input as ReactStrapInput,
  InputProps as ReactStrapInputProps,
} from "reactstrap";

interface InputProps extends ReactStrapInputProps {
  errorMessage?: string;
}

export const Input = (props: InputProps) => {
  const [touched, setTouched] = useState(false);
  return (
    <FormGroup>
      <ReactStrapInput
        {...props}
        onBlur={(e) => {
          setTouched(true);
          props.onBlur && props.onBlur(e);
        }}
        invalid={props.invalid && touched}
      />
      {props.invalid && touched && props.errorMessage && (
        <FormFeedback>{props.errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};
