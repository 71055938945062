import { useApi } from "./api";
import axios from "axios";
import { EmployerCode } from "../models/EmployerCode";
import { useCallback, useMemo } from "react";
import { Employer } from "../models/Employer";
import { SurveyResult } from "../models/SurveyResult";

export const useEmployerApi = () => {
  const { buildUrl, getHeaders } = useApi();
  const getEmployerCode = async () => {
    const response = await axios.get(buildUrl(`Employer/code`), {
      headers: await getHeaders(),
    });
    return response.data;
  };

  const saveEmployerCode = async (employerCode: EmployerCode) => {
    try {
      await axios.post(buildUrl("Employer/code"), employerCode, {
        headers: await getHeaders(),
      });
      return true;
    } catch {
      return false;
    }
  };

  const sendEmployerCodeEmail = async (emailAddresses: string[]) => {
    await axios.post(buildUrl("Employer/emailcode"), emailAddresses, {
      headers: await getHeaders(),
    });
  };

  // Returns Payment Intent client secret
  const createPaymentIntent = useMemo(
    () => async (): Promise<string> => {
      const response = await axios.post(
        buildUrl("employer/paymentintent"),
        null,
        {
          headers: await getHeaders(),
        }
      );
      return response.data;
    },
    [buildUrl, getHeaders]
  );

  const confirmEmployerIsPaid = useCallback(
    async (paymentIntentId: string) => {
      await axios.post(
        buildUrl("employer/confirmpayment"),
        { paymentIntentId },
        {
          headers: await getHeaders(),
        }
      );
    },
    [buildUrl, getHeaders]
  );

  const getEmployerPaidStatus = useCallback(async (): Promise<boolean> => {
    const paidStatus = await axios.get(buildUrl("employer/paidstatus"), {
      headers: await getHeaders(),
    });
    return await paidStatus.data;
  }, [buildUrl, getHeaders]);

  const getUserType = useCallback(
    async (userId: string): Promise<"Employee" | "Employer" | null> => {
      const response = await axios.get(
        buildUrl(`employer/checkuser/${userId}`),
        { headers: await getHeaders() }
      );
      return response.data;
    },
    [buildUrl, getHeaders]
  );

  const createEmployerAccount = useCallback(
    async (employer: Employer) => {
      await axios.post(
        buildUrl("employer/create"),
        { ...employer },
        { headers: await getHeaders() }
      );
    },
    [buildUrl, getHeaders]
  );

  const getEmployerInformation = useCallback(
    async (userId: string): Promise<Employer | null> => {
      try {
        const response = await axios.get(buildUrl(`employer/${userId}`), {
          headers: await getHeaders(),
        });
        return response.data;
      } catch (error) {
        if (error instanceof Error && error.message.includes("401")) {
          return null;
        } else {
          throw error;
        }
      }
    },
    [buildUrl, getHeaders]
  );

  const getSurveyResults = useCallback(async (): Promise<SurveyResult[]> => {
    const response = await axios.get(buildUrl("employer/surveyresults"), {
      headers: await getHeaders(),
    });
    return response.data;
  }, [buildUrl, getHeaders]);

  const getSurveyAverageScore = useCallback(async (): Promise<number> => {
    const response = await axios.get(buildUrl("employer/surveyscore"), {
      headers: await getHeaders(),
    });
    return response.data;
  }, [buildUrl, getHeaders]);

  return {
    getEmployerCode,
    saveEmployerCode,
    sendEmployerCodeEmail,
    createPaymentIntent,
    confirmEmployerIsPaid,
    getEmployerPaidStatus,
    getUserType,
    createEmployerAccount,
    getEmployerInformation,
    getSurveyResults,
    getSurveyAverageScore,
  };
};
