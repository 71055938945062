import "./App.scss";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Login } from "./pages/Login";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Logout } from "./pages/Logout";
import LandingPage from "./pages/LandingPage/LandingPage";
import { Payment } from "./pages/Payment/Payment";
import { PaymentConfirmation } from "./pages/Payment/PaymentConfirmation";
import { useEffect, useState } from "react";
import { useEmployerApi } from "./api/employerApi";
import { EmployeeCode } from "./pages/Employee/EmployeeCode";
import { SuggestEmployer } from "./pages/Employee/SuggestEmployer";
import { CantFindEmployerCode } from "./pages/Employee/CantFindEmployerCode";
import { Redirect } from "./pages/Redirect";
import { CreateEmployerAccount } from "./pages/CreateAccount/CreateEmployerAccount";
import { Survey } from "./pages/Survey/Survey";
import { Success } from "./pages/Survey/Success";
import { ToastContainer } from "react-toastify";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_API_URL}
    >
      <ToastContainer hideProgressBar={true} closeButton={false} />
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AuthenticatedRoute>
                <Redirect />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={paths.landingPage}
            element={
              <AuthenticatedRoute>
                <PaidRoute>
                  <LandingPage />
                </PaidRoute>
              </AuthenticatedRoute>
            }
          />
          <Route
            path={paths.payment}
            element={
              <AuthenticatedRoute>
                <Payment />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={paths.paymentConfirmation}
            element={
              <AuthenticatedRoute>
                <PaymentConfirmation />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={paths.createEmployerAccount}
            element={<CreateEmployerAccount />}
          />
          <Route path={paths.login} element={<Login />} />
          <Route path={paths.logout} element={<Logout />} />
          <Route path={paths.employeeCode} element={<EmployeeCode />} />
          <Route path={paths.suggestEmployer} element={<SuggestEmployer />} />
          <Route
            path={paths.cantFindEmployerCode}
            element={<CantFindEmployerCode />}
          />
          <Route path={paths.survey} element={<Survey />} />
          <Route path={paths.success} element={<Success />} />
          <Route
            path={paths.dashboard}
            element={
              <AuthenticatedRoute>
                <PaidRoute>
                  <AdminDashboard />
                </PaidRoute>
              </AuthenticatedRoute>
            }
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>404 what huh?</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export const paths = {
  logout: "/logout",
  login: "/login",
  payment: "/payment",
  paymentConfirmation: "/paymentconfirmation",
  employeeCode: "/employeecode",
  suggestEmployer: "/suggestemployer",
  cantFindEmployerCode: "/cantfindemployercode",
  landingPage: "/landingpage",
  createEmployerAccount: "/createemployeraccount",
  survey: "/survey",
  success: "/success",
  // dashboard: "/dashboard",
  dashboard: "/dashboard",
};

const AuthenticatedRoute = (props: { children: JSX.Element }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    return <Navigate to={paths.login} replace />;
  }

  return props.children;
};

const PaidRoute = (props: { children: JSX.Element }) => {
  const [employerPaidStatus, setEmployerPaidStatus] = useState<boolean | null>(
    null
  );
  const { getEmployerPaidStatus } = useEmployerApi();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const status = await getEmployerPaidStatus();
      setEmployerPaidStatus(status);
      if (status === false) {
        navigate(paths.payment);
      }
    })();
  }, [getEmployerPaidStatus, setEmployerPaidStatus, navigate]);
  if (employerPaidStatus === false) {
    return <Navigate to={paths.payment} replace />;
  }

  return employerPaidStatus === null ? null : props.children;
};
export default App;
