import { useState, useEffect, useMemo } from "react";
import { Button, Progress } from "reactstrap";
import { useEmployeeApi } from "../../api/employeeApi";
import { Question } from "./Question";
import { StarsQuestion } from "./StarsQuestions";
import { SurveyQuestion } from "../../models/Survey";
import { EmployeeSurveyResponse } from "../../models/EmployeeSurveyResponse";
import { useNavigate } from "react-router-dom";
import { paths } from "../../App";

export const Survey = () => {
  const [stepNumber, setStepNumber] = useState(0);
  const [responses, setResponses] = useState<
    { responseId: string; metadata?: string }[][]
  >([]);

  const [surveyQuestions, setSurveyQuestions] = useState<
    SurveyQuestion[] | null
  >(null);
  const { getSurvey, saveSurvey, deleteSurvey, getEmployeeSavedSurvey } =
    useEmployeeApi();
  const navigate = useNavigate();

  const onClickDelete = async () => {
    await deleteSurvey();
    setResponses([]);
    setStepNumber(0);
  };

  useEffect(() => {
    (async () => {
      const s = await getSurvey();
      setSurveyQuestions(s);
    })();
  }, [getSurvey, setSurveyQuestions]);

  useEffect(() => {
    if (!surveyQuestions) return;
    (async () => {
      const savedSurvey = await getEmployeeSavedSurvey();
      if (!savedSurvey || savedSurvey.length === 0) return;
      const savedResponses = surveyQuestions.map((question) => {
        return savedSurvey
          .filter((saved) => saved.surveyQuestionId === question.id)
          .map((saved) => {
            return {
              responseId: saved.surveyResponseId,
              metadata: saved.responseMetadata,
            };
          });
      });
      setResponses(savedResponses);
    })();
  }, [getEmployeeSavedSurvey, surveyQuestions]);

  const handleUpdateResponse = (
    responseId: string,
    index: number,
    allowMultiple: boolean
  ) => {
    const responsesSelectedCopy = [...responses];
    if (allowMultiple) {
      if (
        responsesSelectedCopy[index]?.filter((r) => r.responseId === responseId)
          ?.length > 0
      ) {
        responsesSelectedCopy[index].splice(
          responsesSelectedCopy[index].findIndex(
            (y) => y.responseId === responseId
          ),
          1
        );
      } else {
        if (!responsesSelectedCopy[index]) {
          responsesSelectedCopy[index] = [{ responseId }];
        } else {
          responsesSelectedCopy[index].push({ responseId });
        }
      }
    } else {
      responsesSelectedCopy[index] = [{ responseId }];
    }
    setResponses(responsesSelectedCopy);
  };

  const handleUpdateMetadata = (
    responseId: string,
    index: number,
    metadata: string
  ) => {
    const responsesSelectedCopy = [...responses];
    responsesSelectedCopy[index].splice(
      responsesSelectedCopy[index].findIndex(
        (y) => y.responseId === responseId
      ),
      1
    );
    responsesSelectedCopy[index].push({ responseId, metadata });
    setResponses(responsesSelectedCopy);
  };

  const submit = async () => {
    if (!surveyQuestions) return;
    const surveyToSave: EmployeeSurveyResponse[] = surveyQuestions.flatMap(
      (question, index) => {
        return responses[index].map((response) => {
          return {
            surveyQuestionId: question.id,
            surveyResponseIds: [response.responseId],
            responseMetadata: response.metadata,
          };
        });
      }
    );
    await saveSurvey(surveyToSave);
    navigate(paths.success);
  };

  const continueButtonDisabled = () => {
    return !responses[stepNumber] || responses[stepNumber].length === 0;
  };

  const isStarsQuestion = useMemo(() => {
    // Assuming that if the possible responses are just numbers, then it's the pick a number of stars question
    return surveyQuestions?.[stepNumber].surveyResponseOptions.every((option) =>
      Number(option.responseOption)
    );
  }, [stepNumber, surveyQuestions]);

  return (
    <div className="vh-100 d-flex flex-column justify-content-between p-4">
      <div>
        {surveyQuestions && !isStarsQuestion && (
          <Question
            question={surveyQuestions[stepNumber]}
            responsesSelected={responses[stepNumber]}
            handleClick={(responseId) => {
              handleUpdateResponse(
                responseId,
                stepNumber,
                surveyQuestions[stepNumber].allowMultipleResponses
              );
            }}
            handleUpdateMetadata={(responseId, metadata) =>
              handleUpdateMetadata(responseId, stepNumber, metadata)
            }
          />
        )}
        {surveyQuestions && isStarsQuestion && (
          <StarsQuestion
            question={surveyQuestions[stepNumber]}
            responsesSelected={responses[stepNumber]?.[0].responseId}
            handleClick={(responseId) => {
              handleUpdateResponse(responseId, stepNumber, false);
            }}
          />
        )}

        {surveyQuestions && stepNumber === surveyQuestions.length - 1 ? (
          <div>
            <Button
              color="primary"
              onClick={() => submit()}
              disabled={continueButtonDisabled()}
            >
              Finish
            </Button>
          </div>
        ) : (
          <div>
            <Button
              color="primary"
              onClick={() => setStepNumber(stepNumber + 1)}
              disabled={continueButtonDisabled()}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
      <div>
        <div className="mt-4">{`${stepNumber + 1} of ${
          surveyQuestions?.length
        }`}</div>
        {surveyQuestions && (
          <Progress
            value={((stepNumber + 1) / surveyQuestions?.length) * 100}
          />
        )}
        <Button color="primary" className="my-4" onClick={onClickDelete}>
          Delete All
        </Button>
      </div>
    </div>
  );
};
