import React from "react";
import styles from "./Banner.module.scss";
import HeaderImageAlt from "../../assets/HeaderImageAlt.png";
import { ParentComponentProps } from "../../interfaces/ParentComponentProps";

export interface BannerProps extends ParentComponentProps {

}

export default function Banner(props: BannerProps) {
  return (
    <div className={styles.BannerComponent}>
      <div className="card bg-transparent text-white border-0 h-100 p1">
        <img className={`${styles.cardImg} card-img h-100`} src={HeaderImageAlt} alt="Card" />
        <div className={`${styles.cardOverlay} card-img-overlay h-100`}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
