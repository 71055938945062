import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useEmployerApi } from "../../api/employerApi";
import { paths } from "../../App";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? "");

export const Payment = () => {
  const { createPaymentIntent, getEmployerPaidStatus } = useEmployerApi();
  const [clientSecret, setClientSecret] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (await getEmployerPaidStatus()) navigate(paths.landingPage);
    })();
  }, [getEmployerPaidStatus, navigate]);

  useEffect(() => {
    (async () => {
      const response = await createPaymentIntent();
      setClientSecret(response);
    })();
  }, [createPaymentIntent]);

  return clientSecret ? (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <Form />
    </Elements>
  ) : null;
};

const Form = () => {
  const elements = useElements();
  const stripe = useStripe();
  const [paymentResult, setPaymentResult] = useState<any>();
  const origin = window.location.origin;
  const navigate = useNavigate();

  const submit = async () => {
    if (!stripe || !elements) return;
    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `${origin}${paths.paymentConfirmation}`,
      },
    });
    if (result.paymentIntent?.status === "succeeded") {
      navigate(
        `${paths.paymentConfirmation}?payment_intent=${result.paymentIntent?.id}&payment_intent_client_secret=${result.paymentIntent?.client_secret}`
      );
    } else {
      // Set the result in state so we can display the error message
      setPaymentResult(result);
    }
  };

  return (
    <AdminLayout
      title="Welcome to SEPOW"
      subtitle="Once your enter your payment details you can unlock all of SEPOW's features."
      showNavbar={false}
    >
      <div>
        <div className="container-fluid">
          <PaymentElement />
          <div className="mt-2 text-danger">
            {paymentResult && paymentResult?.error?.message}
          </div>
          <Button
            onClick={submit}
            disabled={!stripe}
            color="primary"
            className="w-25 mt-4"
          >
            Submit
          </Button>
        </div>
      </div>
    </AdminLayout>
  );
};
