import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import { useEmployeeApi } from "../../api/employeeApi";

export const CantFindEmployerCode = () => {
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [address, setAddress] = useState("");
  const [addressTouched, setAddressTouched] = useState(false);
  const [city, setCity] = useState("");
  const [cityTouched, setCityTouched] = useState(false);
  const [zip, setZip] = useState("");
  const [zipTouched, setZipTouched] = useState(false);
  const [state, setState] = useState("");
  const [stateTouched, setStateTouched] = useState(false);
  const { findEmployerCode } = useEmployeeApi();
  const { user } = useAuth0();

  const formValid = () => {
    return name && address && city && zipValid() && state;
  };

  const zipValid = () => {
    return zip && !Number.isNaN(parseInt(zip));
  };

  const submit = () => {
    setName("");
    setNameTouched(false);
    setAddress("");
    setAddressTouched(false);
    setCity("");
    setCityTouched(false);
    setZip("");
    setZipTouched(false);
    setState("");
    setStateTouched(false);
    findEmployerCode(name, user?.email ?? "");
    toast("Success", { type: "success" });
  };

  return (
    <div className="employee-code-screen">
      <div className="employee-code-card">
        <h2 className="mb-3 text-center">Cannot Find Survey Code</h2>
        <p className="text-black-50">
          <div>
            If you are taking the survey for work please submit your company
            name and address below. We will notify your employer so they can
            send you the survey code. If you are taking this survey as part of a
            community, please reach out to{" "}
            <a href="sepownow@gmail.com">sepownow@gmail.com</a> for help.
          </div>
        </p>
        <Input
          className="mb-3"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Company Name"
          invalid={!name && nameTouched}
          onBlur={() => setNameTouched(true)}
        />
        <Input
          className="mb-3"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
          invalid={!address && addressTouched}
          onBlur={() => setAddressTouched(true)}
        />
        <Input
          className="mb-3"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
          invalid={!city && cityTouched}
          onBlur={() => setCityTouched(true)}
        />
        <Input
          className="mb-3"
          value={zip}
          onChange={(e) => {
            if (
              e.target.value.length > 5 ||
              (Number.isNaN(parseInt(e.target.value)) && e.target.value !== "")
            )
              return;
            setZip(e.target.value);
          }}
          placeholder="Zip Code"
          invalid={!zipValid() && zipTouched}
          onBlur={() => setZipTouched(true)}
        />
        <Input
          className="mb-3"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
          invalid={!state && stateTouched}
          onBlur={() => setStateTouched(true)}
        />
        <Button
          color="primary"
          onClick={() => {
            if (formValid()) {
              submit();
            } else {
              setNameTouched(true);
              setAddressTouched(true);
              setCityTouched(true);
              setZipTouched(true);
              setStateTouched(true);
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
