import React from "react";
import styles from "./Logo.module.scss";
import SiteLogo from "../../assets/blacklogo.png"

export interface LogoProps {}

export default function Logo(props: LogoProps) {
  return (
    <div className={styles.LogoComponent}>
      <img width="50px" src={SiteLogo} alt=""></img>
    </div>
  );
}
