import React from "react";
import { Navbar, Container } from "reactstrap";
import Banner from "../../components/Banner/Banner";
import Sidebar from "../../components/Sidebar/Sidebar";
import { ParentComponentProps } from "../../interfaces/ParentComponentProps";
import { Employer } from "../../models/Employer";
import styles from "./AdminLayout.module.scss";

export interface AdminLayoutProps extends ParentComponentProps {
  employer?: Employer | null;
  title?: string;
  subtitle?: string;
  showNavbar: boolean;
}

export default function AdminLayout(props: AdminLayoutProps) {
  return (
    <div className={styles.AdminLayout}>
      {/* Sidebar */}
      <Sidebar isOpen={true}></Sidebar>

      <Container>
        {/* Navbar */}
        {props.showNavbar && (
          <Navbar className={styles.Navbar}>
            <Container className="justify-end">
              <span className="username">
                {props.employer ? " " + props.employer?.name : ""}
              </span>
            </Container>
          </Navbar>
        )}

        {/* Banner */}
        <Banner>
          <h1 className={styles.title}>{props.title}</h1>
          <p className={styles.content}>{props.subtitle}</p>
        </Banner>
        {/*  Content */}
        <div className="mt-4 p-4">{props.children}</div>
      </Container>
    </div>
  );
}

AdminLayout.defaultProps = {
  showNavbar: true,
};
