import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import { useEmployeeApi } from "../../api/employeeApi";
import { validateEmail } from "../../utilities/validate";

export const SuggestEmployer = () => {
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [address, setAddress] = useState("");
  const [addressTouched, setAddressTouched] = useState(false);
  const [city, setCity] = useState("");
  const [cityTouched, setCityTouched] = useState(false);
  const [zip, setZip] = useState("");
  const [zipTouched, setZipTouched] = useState(false);
  const [state, setState] = useState("");
  const [stateTouched, setStateTouched] = useState(false);
  const { suggestEmployer } = useEmployeeApi();

  const formValid = () => {
    return (
      name && validateEmail(email) && address && city && zipValid() && state
    );
  };

  const zipValid = () => {
    return zip && !Number.isNaN(parseInt(zip));
  };

  const submit = () => {
    setName("");
    setNameTouched(false);
    setEmail("");
    setEmailTouched(false);
    setAddress("");
    setAddressTouched(false);
    setCity("");
    setCityTouched(false);
    setZip("");
    setZipTouched(false);
    setState("");
    setStateTouched(false);
    suggestEmployer({
      companyName: name,
      email,
      address,
      city,
      zipCode: zip,
      state,
    });
    toast("Success", { type: "success" });
  };

  return (
    <div className="employee-code-screen">
      <div className="employee-code-card">
        <h2 className="mb-3 text-center">Suggest Your Employer to SEPOW</h2>
        <p className="text-black-50">
          We're happy to get suggestions on companies who would benefit from our
          platform. Let us know the company name and address for future
          reference!
        </p>
        <Input
          className="mb-3"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Company Name"
          invalid={!name && nameTouched}
          onBlur={() => setNameTouched(true)}
        />
        <Input
          className="mb-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Company Email Address"
          invalid={!validateEmail(email) && emailTouched}
          onBlur={() => setEmailTouched(true)}
        />
        <Input
          className="mb-3"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
          invalid={!address && addressTouched}
          onBlur={() => setAddressTouched(true)}
        />
        <Input
          className="mb-3"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
          invalid={!city && cityTouched}
          onBlur={() => setCityTouched(true)}
        />
        <Input
          className="mb-3"
          value={zip}
          onChange={(e) => {
            if (
              e.target.value.length > 5 ||
              (Number.isNaN(parseInt(e.target.value)) && e.target.value !== "")
            )
              return;
            setZip(e.target.value);
          }}
          placeholder="Zip Code"
          invalid={!zipValid() && zipTouched}
          onBlur={() => setZipTouched(true)}
        />
        <Input
          className="mb-3"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
          invalid={!state && stateTouched}
          onBlur={() => setStateTouched(true)}
        />
        <Button
          color="primary"
          onClick={() => {
            if (formValid()) {
              submit();
            } else {
              setNameTouched(true);
              setAddressTouched(true);
              setCityTouched(true);
              setZipTouched(true);
              setStateTouched(true);
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
