import { Col, Input, Row } from "reactstrap";
import { SurveyQuestion } from "../../models/Survey";
import { SurveyResponseToggle } from "./SurveyResponseToggle";

export const Question = (props: {
  question: SurveyQuestion;
  responsesSelected: { responseId: string; metadata?: string }[] | null;
  handleClick: (responseId: string) => void;
  handleUpdateMetadata: (responseId: string, metadata: string) => void;
}) => {
  const { question, responsesSelected, handleClick, handleUpdateMetadata } =
    props;

  return (
    <>
      <h2 className="mb-5">{question.question}</h2>
      <div className="d-flex justify-content-center align-items-center">
        <Row>
          {question.surveyResponseOptions.map((option, index) => {
            const optionIsSelected = responsesSelected
              ?.map((response) => response.responseId)
              .includes(option.id);
            return (
              <Col key={index}>
                <SurveyResponseToggle
                  isSelected={optionIsSelected ?? false}
                  text={option.responseOption}
                  onClick={() => handleClick(option.id)}
                />
                {optionIsSelected && option.collectResponseMetadata && (
                  <Input
                    value={
                      responsesSelected?.find(
                        (response) => response.responseId === option.id
                      )?.metadata ?? ""
                    }
                    onChange={(e) => {
                      handleUpdateMetadata(option.id, e.target.value);
                    }}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
