import { HTMLProps } from "react";

interface SurveyResponseToggleProps extends HTMLProps<HTMLDivElement> {
  isSelected: boolean;
  text: string;
}

export const SurveyResponseToggle = (props: SurveyResponseToggleProps) => {
  const { isSelected, text, ...rest } = props;
  return (
    <div className="d-flex justify-content-center">
      <div
        className={`survey-response-toggle ${
          isSelected ? "survey-response-toggle-selected" : ""
        }`}
        role="button"
        {...rest}
      >
        <span className="w-75">{text}</span>
        <span className="survey-response-toggle-radio-button" />
      </div>
    </div>
  );
};
