import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useEmployerApi } from "../api/employerApi"
import { paths } from "../App";

export const Redirect = () => {
    const { getUserType } = useEmployerApi();
    const { user } = useAuth0();
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if (!user || !user.sub) return;
            const userType = await getUserType(user.sub)
            if (userType === 'Employer') {
                navigate(paths.landingPage)
            } else if (userType === 'Employee') {
                navigate(paths.survey)
            } else {
                navigate(paths.employeeCode)
            }
        })()
    }, [getUserType, user, navigate])

    return (<div className="vh-100 d-flex justify-content-center align-items-center"><Spinner size="lg" /></div>)
}