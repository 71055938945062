import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { paths } from "../../App";
import { useEffect, useState } from "react";
import { useEmployeeApi } from "../../api/employeeApi";

export const Success = () => {
  const navigate = useNavigate();
  const [surveyScore, setSurveyScore] = useState<number | null>(null);
  const { getSurveyAverageScore } = useEmployeeApi();

  useEffect(() => {
    (async () => {
      const score = await getSurveyAverageScore();
      setSurveyScore(score);
    })();
  }, [getSurveyAverageScore, setSurveyScore]);

  return (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center p-4">
      <div>
        {`Thanks, we really appreciate your feedback! By sharing your experiences,
        you've contributed to fostering an inclusive environment in your
        community or organization. We take every precaution to ensure you can't
        be identified with what you shared on Sepow's platform. The Inclusive
        score for the community taking this survey is ${surveyScore?.toPrecision(
          2
        )} out of 5. A lower inclusive
        score may highlight areas for reflection and attention. A higher
        inclusive score may indicate strengths in perceived inclusivity.`}
      </div>
      <Button
        color="primary"
        className="my-4"
        onClick={() => navigate(paths.logout)}
      >
        Done
      </Button>
    </div>
  );
};
