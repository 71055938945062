import React from "react";
import NavBarIcon from "../../assets/NavBarIcon.svg";
import { paths } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import Logo from  "../Logo/Logo";


export interface SidebarProps {
  isOpen: boolean;
}

const getToggleClass = (open: boolean) => {
  return `${open ? "navbar-open" : "navbar-closed"}`;
};

export default function Sidebar(props: SidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;

  const getActiveClass = (path: string) => {
    const isActive = activePath === path;
    const classList: string[] = ["navbar-item"];
    if (isActive) {
      classList.push("selected");
    }
    return classList.join(" ");
  };

  return (
    <div
      className={styles.SidebarComponent + " " + getToggleClass(props.isOpen)}
    >
      <Logo></Logo>
      <div
        className={getActiveClass(paths.landingPage)}
        onClick={() => navigate("/")}
      >
        <img src={NavBarIcon} alt="" className="ms-4 me-4" />
        <span>Employees</span>
      </div>
      <div
        className={getActiveClass(paths.dashboard)}
        onClick={() => navigate(paths.dashboard)}
      >
        <img src={NavBarIcon} alt="" className="ms-4 me-4" />
        <span>Dashboard</span>
      </div>
      <div className="navbar-item" onClick={() => navigate(paths.logout)}>
        <img src={NavBarIcon} alt="" className="ms-4 me-4" />
        <span>Logout</span>
      </div>
    </div>
  );
}
