import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormFeedback, Input } from "reactstrap";
import { useEmployeeApi } from "../../api/employeeApi";
import { paths } from "../../App";

export const EmployeeCode = () => {
  const [code, setCode] = useState("");
  const [formTouched, setFormTouched] = useState(false);
  const navigate = useNavigate();
  const { saveEmployerCode } = useEmployeeApi();
  const [codeNotFound, setCodeNotFound] = useState(false);

  return (
    <div className="employee-code-screen">
      <div className="employee-code-card">
        <h2 className="mb-3">Input Survey Code</h2>
        <p className="text-black-50">
          This code should have been sent to you from a Sepow representative or
          if you are taking this survey for work, it should have been sent from
          your employer.
        </p>
        <Input
          value={code}
          invalid={(!code && formTouched) || codeNotFound}
          onBlur={() => setFormTouched(true)}
          onChange={(e) => {
            setCode(e.target.value);
            setCodeNotFound(false);
          }}
          placeholder="Enter Survey Code"
        />
        <FormFeedback valid={!codeNotFound}>Code not found</FormFeedback>
        <div className="mb-3" />
        <Button
          className="mb-3"
          color="primary"
          onClick={async () => {
            if (!code) {
              setFormTouched(true);
            } else {
              const success = await saveEmployerCode(code);
              if (success) {
                navigate(paths.survey);
              } else {
                setCodeNotFound(true);
              }
            }
          }}
        >
          Submit
        </Button>

        <p
          className="fw-bold pointer"
          role="button"
          onClick={() => navigate(paths.cantFindEmployerCode)}
        >
          I cannot find my survey code
        </p>
        <p role="button" onClick={() => navigate(paths.createEmployerAccount)}>
          I'm an Administrator
        </p>
      </div>
    </div>
  );
};
